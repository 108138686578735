import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import dayjs from "dayjs"
import { graphql } from "gatsby"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"

import { useAnalytics } from "../hooks"

import { Layout, SEO, SignOffMailingList, RichText } from "../components"
import { blogArticleType } from "../types"
import { blogArticleSlug } from "../utilities"

interface Props {
  data: { contentfulBlogArticles: blogArticleType }
}

export default ({ data }: Props) => {
  const analytics = useAnalytics()
  analytics.track(["User", "Page View"], {
    page: blogArticleSlug(data.contentfulBlogArticles.slug),
  })

  const url =
    process.env.GATSBY_SITE_URL +
    blogArticleSlug(data.contentfulBlogArticles.slug)
  const article = data.contentfulBlogArticles

  return (
    <Layout>
      <SEO
        title={article.seo.title}
        description={article.seo.description.description}
        image={article.seo.socialImage?.file?.url}
        updatedAt={article.updatedAt}
        createdAt={article.posted}
        article={true}
        url={url}
      />
      <header className="articleHeader">
        <section className="content">
          <span className="label">{article.topic.name}</span>
          <h1>{article.title}</h1>
          <div className="author">
            {article.author.image && (
              <GatsbyImage
                className="photo"
                image={article.author.image?.gatsbyImageData}
                alt={article.author.image?.title}
              />
            )}
            <span>
              {article.author.name} &bull;{" "}
              {dayjs(article.posted).format("MMMM D, YYYY")}
            </span>
          </div>
        </section>
        <GatsbyImage
          className="cover"
          image={article.featuredImage.gatsbyImageData}
          alt={article.featuredImage.title}
        />
      </header>
      <main>
        <section className="articleWrapper">
          <aside className="shareIcons">
            <span className="title">Share</span>
            <FacebookShareButton url={url}>
              <FacebookIcon round={true} bgStyle={{ fill: "#006CF9" }} />
            </FacebookShareButton>
            <TwitterShareButton url={url}>
              <TwitterIcon round={true} bgStyle={{ fill: "#006CF9" }} />
            </TwitterShareButton>
            <LinkedinShareButton url={url}>
              <LinkedinIcon round={true} bgStyle={{ fill: "#006CF9" }} />
            </LinkedinShareButton>
          </aside>
          <article className="article">
            <RichText content={article.content} />
          </article>
        </section>
      </main>
      <SignOffMailingList />
    </Layout>
  )
}

export const pageQuery = graphql`
  query Article($contentful_id: String!) {
    contentfulBlogArticles(contentful_id: { eq: $contentful_id }) {
      author {
        image {
          gatsbyImageData
          title
        }
        name
        jobTitle
        contentful_id
      }
      content {
        raw
      }
      featuredImage {
        gatsbyImageData
        title
      }
      preview {
        preview
      }
      slug
      title
      posted
      topic {
        name
      }
      createdAt
      updatedAt
      seo {
        socialImage {
          file {
            url
          }
        }
        title
        description {
          description
        }
      }
    }
  }
`
